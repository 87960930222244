import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "students" }
const _hoisted_2 = { class: "students__students-top-block-wrap" }
const _hoisted_3 = { class: "students__search-wrap" }
const _hoisted_4 = { class: "students__filters-wrap" }
const _hoisted_5 = {
  key: 0,
  class: "calendar__data-range-wrap"
}
const _hoisted_6 = {
  key: 1,
  for: "subscription-no",
  class: "custom-checkbox"
}
const _hoisted_7 = {
  key: 2,
  for: "subscription",
  class: "custom-checkbox"
}
const _hoisted_8 = {
  for: "withtout-instructor",
  class: "custom-checkbox"
}
const _hoisted_9 = {
  for: "just-parents",
  class: "custom-checkbox"
}
const _hoisted_10 = {
  for: "with-deactivation-date",
  class: "custom-checkbox"
}
const _hoisted_11 = { class: "students__search-wrap-mobile-container" }
const _hoisted_12 = { class: "students__search-wrap mobile-search" }
const _hoisted_13 = { class: "main-table__mobile-wrap" }
const _hoisted_14 = { class: "main-table__head" }
const _hoisted_15 = { class: "main-table__head-id" }
const _hoisted_16 = { class: "main-table__sort-block" }
const _hoisted_17 = { class: "main-table__head-big-tr" }
const _hoisted_18 = { class: "main-table__sort-block" }
const _hoisted_19 = { class: "main-table__head-big-tr" }
const _hoisted_20 = { class: "main-table__sort-block" }
const _hoisted_21 = { class: "main-table__head-big-tr" }
const _hoisted_22 = { class: "main-table__sort-block" }
const _hoisted_23 = { class: "main-table__head-big-tr" }
const _hoisted_24 = { class: "main-table__sort-block" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "main-table__body-id" }
const _hoisted_27 = { class: "main-table__body-little-tr" }
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "main-table__body-big-tr" }
const _hoisted_30 = { class: "main-table__body-big-tr" }
const _hoisted_31 = { class: "main-table__body-big-tr" }
const _hoisted_32 = { class: "main-table__body-big-tr" }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = {
  key: 0,
  class: "main-table__body-big-tr main-table__body-big-tr-show"
}
const _hoisted_36 = ["onClick"]
const _hoisted_37 = ["onClick"]
const _hoisted_38 = ["onClick"]
const _hoisted_39 = { class: "main-table__body-id" }
const _hoisted_40 = { class: "main-table__body-big-tr" }
const _hoisted_41 = { class: "main-table__body-big-tr" }
const _hoisted_42 = { class: "main-table__body-big-tr" }
const _hoisted_43 = { class: "main-table__body-big-tr" }
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { class: "students__choose-instructor students__create-student-wrap" }
const _hoisted_47 = {
  key: 0,
  class: "students__create-student-steps"
}
const _hoisted_48 = {
  key: 1,
  class: "students__create-student-second-step"
}
const _hoisted_49 = { class: "students__form" }
const _hoisted_50 = { class: "students__images-container" }
const _hoisted_51 = { class: "students__photo-block" }
const _hoisted_52 = ["src"]
const _hoisted_53 = {
  key: 1,
  class: "students__upload-photo"
}
const _hoisted_54 = { key: 0 }
const _hoisted_55 = { class: "form-group" }
const _hoisted_56 = { class: "students__input-wrap" }
const _hoisted_57 = { key: 0 }
const _hoisted_58 = { class: "form-group" }
const _hoisted_59 = { class: "students__input-wrap" }
const _hoisted_60 = { key: 0 }
const _hoisted_61 = { class: "form-group" }
const _hoisted_62 = { class: "students__input-wrap" }
const _hoisted_63 = { key: 0 }
const _hoisted_64 = { class: "form-group" }
const _hoisted_65 = {
  key: 2,
  class: "students__create-student-second-step"
}
const _hoisted_66 = { class: "students__search-wrap students__search-choose-instructor" }
const _hoisted_67 = { class: "students__choose-instructor-wrap" }
const _hoisted_68 = ["for"]
const _hoisted_69 = ["id", "name", "onChange", "checked"]
const _hoisted_70 = { key: 0 }
const _hoisted_71 = { key: 1 }
const _hoisted_72 = { key: 2 }
const _hoisted_73 = { key: 3 }
const _hoisted_74 = { key: 4 }
const _hoisted_75 = {
  key: 3,
  class: "students__create-student-second-step"
}
const _hoisted_76 = { class: "students__photo-mock-wrap" }
const _hoisted_77 = ["src", "alt"]
const _hoisted_78 = { class: "students__value" }
const _hoisted_79 = { class: "students__value" }
const _hoisted_80 = { class: "students__value" }
const _hoisted_81 = { class: "students__value" }
const _hoisted_82 = {
  key: 4,
  class: "students__create-student-second-step students__create-student-last-step"
}
const _hoisted_83 = { class: "students__popup-flex" }
const _hoisted_84 = { class: "students__popup-text" }
const _hoisted_85 = { class: "students__popup-email" }
const _hoisted_86 = {
  key: 2,
  class: "students__copied"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_Search = _resolveComponent("Search")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_SelectArrow = _resolveComponent("SelectArrow")!
  const _component_CalendarOneDate = _resolveComponent("CalendarOneDate")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Minuse = _resolveComponent("Minuse")!
  const _component_Pluse = _resolveComponent("Pluse")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_UploadPhoto = _resolveComponent("UploadPhoto")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_CreateStudent = _resolveComponent("CreateStudent")!
  const _component_Field = _resolveComponent("Field")!
  const _component_PhoneIcon = _resolveComponent("PhoneIcon")!
  const _component_EmailIcon = _resolveComponent("EmailIcon")!
  const _component_PhotoMock = _resolveComponent("PhotoMock")!
  const _component_StudentLetter = _resolveComponent("StudentLetter")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AdminPanelNavMenu),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Search by name or email",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchData) = $event))
        }, null, 512), [
          [_vModelText, _ctx.searchData]
        ]),
        _createVNode(_component_Search)
      ]),
      _createElementVNode("div", {
        class: "students__new-student-button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openPopupChoosenInstructorNewStudent && _ctx.openPopupChoosenInstructorNewStudent(...args)))
      }, " New student ")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DateRangePicker, {
        "select-date-fill": _ctx.selectDateFill,
        onOnSelectStaticRange: _ctx.selectStaticRange,
        onCloseCalendar: _ctx.closeCalendar
      }, null, 8, ["select-date-fill", "onOnSelectStaticRange", "onCloseCalendar"]),
      (this.isSubscriptionEnabled == 'true')
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "calendar__data-range students__calendar-big",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendarSubsqribe && _ctx.toggleCalendarSubsqribe(...args)))
            }, [
              _cache[31] || (_cache[31] = _createTextVNode(" Date of subscription expiration ")),
              _createVNode(_component_SelectArrow, {
                class: _normalizeClass({ isOpenSelectArrowSubsqribe: _ctx.isCalendarSubsqribeVisible })
              }, null, 8, ["class"])
            ]),
            _withDirectives(_createVNode(_component_CalendarOneDate, {
              class: "calendar__calendar",
              "select-date-fill": this.selectDateFillSubsqribe,
              "selected-new-date": this.selectedNewDateSubsqribe
            }, null, 8, ["select-date-fill", "selected-new-date"]), [
              [_vShow, _ctx.isCalendarSubsqribeVisible]
            ])
          ])), [
            [_directive_click_outside, _ctx.closeCalendarSubsqribe]
          ])
        : _createCommentVNode("", true),
      (this.isSubscriptionEnabled == 'true')
        ? (_openBlock(), _createElementBlock("label", _hoisted_6, [
            _createElementVNode("input", {
              id: "subscription-no",
              type: "checkbox",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkedShowSubscription($event, 'show_only_no_subscription')))
            }),
            _cache[32] || (_cache[32] = _createElementVNode("span", null, "Show only no subscription", -1))
          ]))
        : _createCommentVNode("", true),
      (this.isSubscriptionEnabled == 'false')
        ? (_openBlock(), _createElementBlock("label", _hoisted_7, [
            _createElementVNode("input", {
              id: "subscription",
              type: "checkbox",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.checkedShowSubscription($event, 'show_only_active')))
            }),
            _cache[33] || (_cache[33] = _createElementVNode("span", null, "Show only active students", -1))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("label", _hoisted_8, [
        _createElementVNode("input", {
          id: "withtout-instructor",
          type: "checkbox",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.checkedShowSubscription($event, 'without_instructor')))
        }),
        _cache[34] || (_cache[34] = _createElementVNode("span", null, "Without instructor", -1))
      ]),
      _createElementVNode("label", _hoisted_9, [
        _createElementVNode("input", {
          id: "just-parents",
          type: "checkbox",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.checkedShowSubscription($event, 'just_parents')))
        }),
        _cache[35] || (_cache[35] = _createElementVNode("span", null, "Just parents", -1))
      ]),
      _createElementVNode("label", _hoisted_10, [
        _createElementVNode("input", {
          id: "with-deactivation-date",
          type: "checkbox",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.checkedShowSubscription($event, 'with_deactivation_date')))
        }),
        _cache[36] || (_cache[36] = _createElementVNode("span", null, "With deactivation date", -1))
      ]),
      _createElementVNode("div", {
        class: "students__reset-filters",
        onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.resetFilters && _ctx.resetFilters(...args)))
      }, " Reset filters ")
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Search by name or email",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.searchData) = $event))
        }, null, 512), [
          [_vModelText, _ctx.searchData]
        ]),
        _createVNode(_component_Search)
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _cache[37] || (_cache[37] = _createTextVNode(" ID ")),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[10] || (_cache[10] = ($event: any) => (this.getStudents('?search=', 'id')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[11] || (_cache[11] = ($event: any) => (this.getStudents('?search=', '-id'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _cache[42] || (_cache[42] = _createElementVNode("div", { class: "main-table__head-little-tr" }, "Picture", -1)),
        _createElementVNode("div", _hoisted_17, [
          _cache[38] || (_cache[38] = _createTextVNode(" Full Name ")),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[12] || (_cache[12] = ($event: any) => (this.getStudents('?search=', 'full_name')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[13] || (_cache[13] = ($event: any) => (this.getStudents('?search=', '-full_name'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _cache[39] || (_cache[39] = _createTextVNode(" Email ")),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[14] || (_cache[14] = ($event: any) => (this.getStudents('?search=', 'email')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[15] || (_cache[15] = ($event: any) => (this.getStudents('?search=', '-email'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _cache[40] || (_cache[40] = _createTextVNode(" Phone Number ")),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[16] || (_cache[16] = ($event: any) => (this.getStudents('?search=', 'phone')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[17] || (_cache[17] = ($event: any) => (this.getStudents('?search=', '-phone'))),
              class: "main-table__sort-bottom"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _cache[41] || (_cache[41] = _createTextVNode(" Status ")),
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_SortArrow, {
              onClick: _cache[18] || (_cache[18] = ($event: any) => (this.getStudents('?search=', 'status')))
            }),
            _createVNode(_component_SortArrow, {
              onClick: _cache[19] || (_cache[19] = ($event: any) => (this.getStudents('?search=', '-status'))),
              class: "main-table__sort-bottom"
            })
          ])
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.studentsData, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id
        }, [
          _createElementVNode("div", {
            class: "main-table__body",
            onClick: ($event: any) => (_ctx.viewStudent(item.id))
          }, [
            _createElementVNode("div", _hoisted_26, _toDisplayString(item.id), 1),
            _createElementVNode("div", _hoisted_27, [
              (item.image === null)
                ? (_openBlock(), _createBlock(_component_Picture, { key: 0 }))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: item.image,
                    alt: "item.full_name"
                  }, null, 8, _hoisted_28))
            ]),
            _createElementVNode("div", _hoisted_29, _toDisplayString(item.full_name), 1),
            _createElementVNode("div", _hoisted_30, _toDisplayString(item.email), 1),
            _createElementVNode("div", _hoisted_31, _toDisplayString(item?.phone?.value), 1),
            _createElementVNode("div", _hoisted_32, [
              (item?.status == 'active')
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, "Activated"))
                : (_openBlock(), _createElementBlock("div", _hoisted_34, "Deactivated"))
            ]),
            (item?.children?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                  (_ctx.isRowExpanded(item.id))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "main-table__pluse",
                        onClick: _withModifiers(($event: any) => (_ctx.hideAdditionalStudent(item.id)), ["stop"])
                      }, [
                        _createVNode(_component_Minuse)
                      ], 8, _hoisted_36))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "main-table__pluse",
                        onClick: _withModifiers(($event: any) => (_ctx.showAdditionalStudent(item.id)), ["stop"])
                      }, [
                        _createVNode(_component_Pluse)
                      ], 8, _hoisted_37))
                ]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_25),
          (_ctx.isRowExpanded(item.id))
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.children, (child) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: child.id,
                  class: _normalizeClass(["main-table__body main-table__body-additional", { 'active-additional': _ctx.isRowExpanded(item.id) }]),
                  onClick: ($event: any) => (_ctx.viewStudent(child.id))
                }, [
                  _createElementVNode("div", _hoisted_39, _toDisplayString(child.id), 1),
                  _cache[43] || (_cache[43] = _createElementVNode("div", { class: "main-table__body-little-tr" }, null, -1)),
                  _createElementVNode("div", _hoisted_40, _toDisplayString(child.full_name), 1),
                  _createElementVNode("div", _hoisted_41, _toDisplayString(child.email), 1),
                  _createElementVNode("div", _hoisted_42, _toDisplayString(child?.phone?.value), 1),
                  _createElementVNode("div", _hoisted_43, [
                    (child?.banned)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_44, "Deactivated"))
                      : (_openBlock(), _createElementBlock("div", _hoisted_45, "Activated"))
                  ]),
                  _cache[44] || (_cache[44] = _createElementVNode("div", { class: "main-table__body-big-tr main-table__body-big-tr-show" }, null, -1))
                ], 10, _hoisted_38))
              }), 128))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createVNode(_component_Pagination, {
      propertyMeta: _ctx.studentsMeta,
      propertyList: _ctx.studentsData,
      page: _ctx.page,
      handlePage: _ctx.handlePage
    }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"]),
    (_ctx.isModalChooseInstructorNewStudent)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalChooseInstructorNewStudent,
          class: "students__popup"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_46, [
              (this.activeStep !== '4')
                ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["students__popup-steps-title students__popup-steps-title-first", { 'active-step': this.activeStep === '1' }])
                    }, " Student details ", 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["students__popup-steps-title students__popup-steps-title-second", { 'active-step': this.activeStep === '2' }])
                    }, " Choose Instructor ", 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["students__popup-steps-title students__popup-steps-title-third", { 'active-step': this.activeStep === '3' }])
                    }, " Review and Send ", 2)
                  ]))
                : _createCommentVNode("", true),
              (this.activeStep === '1')
                ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                    _cache[50] || (_cache[50] = _createElementVNode("div", { class: "students__create-step-title" }, "Student details", -1)),
                    _createElementVNode("div", _hoisted_49, [
                      _createElementVNode("div", _hoisted_50, [
                        _createElementVNode("div", null, [
                          _cache[45] || (_cache[45] = _createElementVNode("div", { class: "students__photo-title" }, "Photo", -1)),
                          _createElementVNode("label", _hoisted_51, [
                            (this.isPreview)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  src: this.imagePreview,
                                  class: "students__images-preview"
                                }, null, 8, _hoisted_52))
                              : (_openBlock(), _createElementBlock("div", _hoisted_53, [
                                  _createVNode(_component_UploadPhoto)
                                ])),
                            _createElementVNode("input", {
                              type: "file",
                              id: "file",
                              ref: "file",
                              accept: ".png, .jpg, .jpeg",
                              onChange: _cache[20] || (_cache[20] = ($event: any) => (_ctx.handleFileUpload()))
                            }, null, 544)
                          ])
                        ])
                      ]),
                      (this.errorInstructor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                            _createVNode(_component_AlertBlock, {
                              message: this.errorInstructor.image
                            }, null, 8, ["message"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_55, [
                        _cache[46] || (_cache[46] = _createElementVNode("label", {
                          for: "full_name",
                          class: "form-group-label"
                        }, "Full Name ", -1)),
                        _createElementVNode("div", _hoisted_56, [
                          _createVNode(_component_CreateStudent),
                          _createVNode(_component_Field, {
                            class: "form-control",
                            type: "text",
                            name: "full_name",
                            modelValue: this.fullName,
                            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((this.fullName) = $event))
                          }, null, 8, ["modelValue"]),
                          (this.errorInstructor)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                                _createVNode(_component_AlertBlock, {
                                  message: this.errorInstructor.full_name
                                }, null, 8, ["message"])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_58, [
                        _cache[47] || (_cache[47] = _createElementVNode("label", {
                          for: "phone_number",
                          class: "form-group-label"
                        }, "Phone Number ", -1)),
                        _createElementVNode("div", _hoisted_59, [
                          _createVNode(_component_PhoneIcon),
                          _createVNode(_component_Field, {
                            class: "form-control",
                            type: "text",
                            name: "phone_number",
                            modelValue: this.phone,
                            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((this.phone) = $event))
                          }, null, 8, ["modelValue"]),
                          (this.errorInstructor)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                                _createVNode(_component_AlertBlock, {
                                  message: this.errorInstructor.phone
                                }, null, 8, ["message"])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_61, [
                        _cache[48] || (_cache[48] = _createElementVNode("label", {
                          for: "email",
                          class: "form-group-label"
                        }, "Email* ", -1)),
                        _createElementVNode("div", _hoisted_62, [
                          _createVNode(_component_EmailIcon),
                          _createVNode(_component_Field, {
                            class: "form-control",
                            type: "text",
                            name: "email",
                            modelValue: this.email,
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((this.email) = $event))
                          }, null, 8, ["modelValue"]),
                          (this.errorInstructor)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                                _createVNode(_component_AlertBlock, {
                                  message: this.errorInstructor.email
                                }, null, 8, ["message"])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_64, [
                        _createElementVNode("button", {
                          class: "students__button-next students__button-next-boottom",
                          onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.nextStep('2')))
                        }, _cache[49] || (_cache[49] = [
                          _createElementVNode("span", null, "Next", -1)
                        ]))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (this.activeStep === '2')
                ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                    _cache[51] || (_cache[51] = _createElementVNode("div", { class: "students__create-step-title" }, "Choose instructor", -1)),
                    _createElementVNode("div", _hoisted_66, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        placeholder: "Search",
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.searchDataInstructors) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.searchDataInstructors]
                      ]),
                      _createVNode(_component_Search)
                    ]),
                    _createElementVNode("div", _hoisted_67, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.instructorsData, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.id
                        }, [
                          _createElementVNode("label", {
                            for: item.id,
                            class: "custom-checkbox"
                          }, [
                            _createElementVNode("input", {
                              id: item.id,
                              name: item.full_name,
                              onChange: ($event: any) => (_ctx.selectInstructors(item.id, $event)),
                              checked: _ctx.isChecked(item.id),
                              type: "checkbox"
                            }, null, 40, _hoisted_69),
                            _createElementVNode("span", null, _toDisplayString(item.full_name), 1)
                          ], 8, _hoisted_68)
                        ]))
                      }), 128)),
                      (this.errorInstructor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                            _createVNode(_component_AlertBlock, {
                              message: this.errorInstructor.instructor_ids
                            }, null, 8, ["message"])
                          ]))
                        : _createCommentVNode("", true),
                      (this.errorInstructor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_71, [
                            _createVNode(_component_AlertBlock, {
                              message: this.errorInstructor.image
                            }, null, 8, ["message"])
                          ]))
                        : _createCommentVNode("", true),
                      (this.errorInstructor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
                            _createVNode(_component_AlertBlock, {
                              message: this.errorInstructor.full_name
                            }, null, 8, ["message"])
                          ]))
                        : _createCommentVNode("", true),
                      (this.errorInstructor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                            _createVNode(_component_AlertBlock, {
                              message: this.errorInstructor.email
                            }, null, 8, ["message"])
                          ]))
                        : _createCommentVNode("", true),
                      (this.errorInstructor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                            _createVNode(_component_AlertBlock, {
                              message: this.errorInstructor.phone
                            }, null, 8, ["message"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: "students__button-back",
                        onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.prewStep('1')))
                      }, "Back"),
                      _createElementVNode("div", {
                        class: "students__button-next",
                        onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.createStudent()))
                      }, " Next ")
                    ])
                  ]))
                : _createCommentVNode("", true),
              (this.activeStep === '3')
                ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                    _cache[57] || (_cache[57] = _createElementVNode("div", { class: "students__create-step-title" }, "Generate code", -1)),
                    _createElementVNode("div", _hoisted_76, [
                      (!this.imagePreview)
                        ? (_openBlock(), _createBlock(_component_PhotoMock, {
                            key: 0,
                            class: "students__photo-mock"
                          }))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: this.imagePreview,
                            alt: this.studentData?.full_name,
                            class: "students__photo-mock"
                          }, null, 8, _hoisted_77))
                    ]),
                    _createElementVNode("div", null, [
                      _cache[52] || (_cache[52] = _createElementVNode("div", { class: "students__title" }, "Full Name", -1)),
                      _createElementVNode("div", _hoisted_78, _toDisplayString(this.studentData?.full_name), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[53] || (_cache[53] = _createElementVNode("div", { class: "students__title" }, "Email", -1)),
                      _createElementVNode("div", _hoisted_79, _toDisplayString(this.studentData?.email), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[54] || (_cache[54] = _createElementVNode("div", { class: "students__title" }, "Phone Number", -1)),
                      _createElementVNode("div", _hoisted_80, _toDisplayString(this.studentData?.phone?.value), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[55] || (_cache[55] = _createElementVNode("div", { class: "students__title" }, "Instructors", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.studentData?.instructors, (inst) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: inst.id
                        }, [
                          _createElementVNode("div", _hoisted_81, _toDisplayString(inst.full_name), 1)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", {
                      class: "students__button-back",
                      onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.prewStep('2')))
                    }, "Back"),
                    _createElementVNode("div", {
                      class: "students__button-next students__button-create-student",
                      onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.getParentRegistrationLink('4')))
                    }, _cache[56] || (_cache[56] = [
                      _createTextVNode(" Create a student account and send "),
                      _createElementVNode("br", null, null, -1),
                      _createTextVNode(" login instructions to the student`s email ")
                    ]))
                  ]))
                : _createCommentVNode("", true),
              (this.activeStep === '4')
                ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                    _createVNode(_component_StudentLetter, {
                      "full-name": this.fullName,
                      "qr-code-url": this.parentRegistrationLinkData?.qrCodeUrl,
                      "app-link": this.parentRegistrationLinkData?.appLink,
                      "deep-link": this.parentRegistrationLinkData?.passwordSetupLink
                    }, null, 8, ["full-name", "qr-code-url", "app-link", "deep-link"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalSeccess)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 1,
          "on-close": _ctx.handleCloseModalSeccess
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_83, [
              _createElementVNode("div", _hoisted_84, [
                _cache[58] || (_cache[58] = _createElementVNode("div", null, "The instructions was sent to this email", -1)),
                _createElementVNode("div", _hoisted_85, _toDisplayString(this.email), 1)
              ]),
              _createElementVNode("div", {
                class: "students__popup-button-yes",
                onClick: _cache[30] || (_cache[30] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalSeccess && _ctx.handleCloseModalSeccess(...args)))
              }, " OK ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.copied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_86, "Code copied!"))
      : _createCommentVNode("", true)
  ]))
}